import React from "react";
import { Helmet } from "react-helmet";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ title, children }) {
  return (
    <>
      <Helmet title={title} htmlAttributes={{ lang: "en" }}>
        <meta name="description" content="Mobile app for visiting open homes" />
      </Helmet>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
}
