import React, { useState } from "react";
import { Link } from "gatsby";

export default function Header() {
  const [mobileMenuOpen, setMobileOpen] = useState(false);

  return (
    <header class="flex flex-wrap flex-row justify-between md:items-center md:space-x-4 bg-gray-500 text-white py-6 px-6 relative">
      <div className="flex flex-row">
        <Link
          to="/"
          className="text-2xl"
          style={{
            fontFamily: "'Signika Negative', sans-serif",
            fontWeight: 400,
          }}
        >
          SQUAREMETER
        </Link>
        <div className="bg-white text-gray-500 p-1 px-3 ml-3 rounded">BETA</div>
      </div>
      <button
        class={
          "inline-block md:hidden w-8 h-8 bg-white text-gray-600 p-1" +
          (mobileMenuOpen ? " p-0" : "")
        }
        onClick={() => setMobileOpen((x) => !x)}
      >
        {!mobileMenuOpen && (
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        )}
        {mobileMenuOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
        )}
      </button>
      <nav
        class={
          "absolute md:relative top-16 left-0 md:top-0 z-20 md:flex flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto bg-gray-500 shadow-md rounded-lg md:rounded-none md:shadow-none md:bg-transparent p-6 pt-0 md:p-0" +
          (mobileMenuOpen ? " flex" : " hidden")
        }
        onClick={() => setMobileOpen(false)}
      >
        <Link
          to="/"
          className="block no-underline text-xl md:text-sm py-2 px-3 hover:underline"
        >
          Home
        </Link>
        <Link
          to="/contact-us"
          className="block no-underline text-xl md:text-sm py-2 px-3 hover:underline"
        >
          Contact Us
        </Link>
        <Link to="https://web.squaremeter.co.nz" rel="nofollow" className="block no-underline text-sm py-2 px-3 hover:underline">
          Members
        </Link>
      </nav>
    </header>
  );
}
